<template>
  <div v-if="video" class="minimize-iframe">
    <div class="action">
      <span>{{ video.title_ru }}</span>
      <span>
        <!-- <nuxt-link :to="'/watch/' + video.type + '/' + video.token" class="minimize-link" tag="i" /> -->
        <i class="minimize-link" title="Развернуть" @click="showMinimize" />
        <i class="icon-close" title="Закрыть" @click="hideMinimize" />
      </span>
    </div>
    <iframe
      :src="video.src"
      title="Minimize video"
      width="320"
      height="180"
      frameborder="0"
      allowfullscreen
      class="minimize-video"
    />
  </div>
</template>

<script>
export default {
  computed: {
    video() {
      return this.$store.state.minimizeVideo
    }
  },
  methods: {
    showMinimize() {
      // console.log(this.video)
      const video = this.video
      this.$router.push(`/watch/${video.kinopoisk_id}`)
    },
    hideMinimize() {
      this.$store.commit('minimizeVideo', null)
    }
  }
}
</script>

<style lang="stylus">
.minimize-iframe
  position fixed
  bottom 0
  left 0
  background-color #000
  border-radius 0 3px 0 0
  z-index 11
  width 320px
  height 180px
  &:hover > .action
    display grid
  .action
    position absolute
    width 100%
    display none
    padding 5px
    color #fff
    background-color #333
    grid-template-columns auto 40px
    font-size 1.4rem
  i
    display inline-block
    width 15px
    height 15px
    position relative
    cursor pointer
    &.icon-close
      left calc(100% - 40px)
      background url('/static_files/icons/cancel-dark.svg') no-repeat
      background-size 15px 15px
    &.minimize-link
      left calc(100% - 45px)
      background url('/static_files/icons/resize.svg') no-repeat
      background-size 15px 15px
</style>
